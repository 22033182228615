<template>
  <div class="bind-card p20 bsbb">
    <van-nav-bar left-arrow @click-left="onClickLeft" :border="false">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal">支付方式</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <!-- <van-nav-bar title="支付方式" left-arrow @click-left="onClickLeft" :border="false" /> -->
    <van-form @submit="onSubmit" class="fz24">
      <van-cell-group inset>
        <van-field class="df aic jcc" v-model="bindCardMsg.bankCard" name="银行卡号" label="银行卡号" placeholder="银行卡号"
          label-width="200px" :rules="[{ required: true, message: '请填写银行卡号' }]" />
        <van-field class="df aic jcc" v-model="bindCardMsg.realName" name="真实姓名" label="真实姓名" placeholder="真实姓名"
          label-width="200px" :rules="[{ required: true, message: '请填写真实姓名' }]" />
        <van-field class="df aic jcc" v-model="bindCardMsg.bank" name="银行名称" label="银行名称" placeholder="银行名称"
          label-width="200px" :rules="[{ required: true, message: '请填写银行名称' }]" />
      </van-cell-group>
      <div class="df aic jcc">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { bindBankCard, getUserInfo } from "../../utils/api";
export default {
  data() {
    return {
      bindCardMsg: {
        bankCard: "",
        realName: "",
        bank: "",
        userInfo: {}
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit() {
      bindBankCard(this.bindCardMsg).then((res) => {
        console.log(res.data);
      });
    },
  },
  mounted() {
    getUserInfo().then(res => {
      this.userInfo = res.data.user
      this.bindCardMsg.bankCard = res.data.user.bankCard
      this.bindCardMsg.realName = res.data.user.realName
      this.bindCardMsg.bank = res.data.user.bank
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep .van-icon {
  font-size: 32px;
}

::v-deep #van-field-1-label {
  font-size: 28px !important;
}

::v-deep #van-field-2-label {
  font-size: 28px !important;
}

::v-deep #van-field-3-label {
  font-size: 28px !important;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
}

.bind-card {
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: 'pingfang';

  .van-field {
    margin-top: 40px;
  }

  .van-button {
    margin-top: 40px;
    padding: 40px;
    box-sizing: border-box;
    width: 90%;
  }
}
</style>
